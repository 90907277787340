import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment as env, environment } from '@src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@app/shared';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageLoaderComponent } from './shared/components/page-loader/page-loader.component';

// Interceptors
import { ErrorInterceptor, TokenInterceptor } from './core/interceptors';

// NGX loading bar
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

// UI Modules
import { provideNzConfig } from 'ng-zorro-antd/core/config';
import { ngZorroConfig } from './app.ng-zorro-config';

// Store
import { AppRepository } from './app.repository';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from './core/services/auth.service';
import { PreloadAdminGuard } from './core/guards/preload-admin.guard';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyNgZorroAntdModule } from '@ngx-formly/ng-zorro-antd';
import { NzIconService } from 'ng-zorro-antd/icon';
import { icons } from './core/icons';

// Firebase
import { provideAuth, getAuth } from '@angular/fire/auth';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { AuthRepository } from './core/services/auth.repository';
import { HotkeyModule } from 'angular2-hotkeys';

registerLocaleData(en);

export function loadIcons(iconService: NzIconService, http: HttpClient) {
  return () =>
    new Promise<void>((resolve) => {
      iconService.addIcon(...(icons as any));
      resolve();
    });
}

/**
 * The main module of the application.
 *
 * @remarks
 * This module is responsible for bootstrapping the application and configuring the necessary dependencies.
 * It imports various modules such as BrowserModule, AppRoutingModule, SharedModule, HttpClientModule, etc.
 * It also provides services and interceptors required for authentication, error handling, and internationalization.
 * The AppModule class is decorated with @NgModule to define the metadata for the module.
 */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    PageLoaderComponent,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    FormlyModule.forRoot(),
    ReactiveFormsModule,
    FormlyNgZorroAntdModule,
    ScriptLoaderModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    HotkeyModule.forRoot(),
    /* provideAppCheck(() =>
      initializeAppCheck(getApp(), {
        provider: new ReCaptchaV3Provider(environment.recaptchaSiteKey),
        isTokenAutoRefreshEnabled: true,
      }),
    ), */
  ],
  providers: [
    AppRepository,
    AuthService,
    AuthRepository,
    PreloadAdminGuard,
    provideNzConfig(ngZorroConfig),
    {
      provide: APP_INITIALIZER,
      useFactory: loadIcons,
      deps: [NzIconService, HttpClient],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    NzNotificationService,
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
