import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { fadeInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-page-container',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-container.component.html',
  styleUrl: './page-container.component.less',
  animations: [fadeInUpOnEnterAnimation({ anchor: 'enter', duration: 600, translate: '20px' })],
})
export class PageContainerComponent {
  @Input() public padding: string = '24px'

}
